<template>
    <div class="tabbar" @click="onTabbar()">
        <van-tabbar v-model="active" active-color="#272727" inactive-color="#8B8B8B" @change="onChange">
            <van-tabbar-item name="home">
                <span :class="active == 'home' ? 'text_active' : 'text_default'">项目中心</span>
                <template #icon="props">
                    <img :src="props.active ? '/static/project-selected.png' :  '/static/project.png' " />
                </template>
            </van-tabbar-item>
            <!-- <van-tabbar-item name="video">
                <span style="font-weight:500;">视频中心</span>
                <template #icon="props">
                    <img :src="props.active ? '/static/video-selected.png' :  '/static/video.png' " />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="class">
                <span style="font-weight:500;">云课堂</span>
                <template #icon="props">
                    <img :src="props.active ? '/static/class-selected.png' :  '/static/class.png' " />
                </template>
            </van-tabbar-item> -->
            <van-tabbar-item name="me">
                <span :class="active == 'me' ? 'text_active' : 'text_default'">个人资料</span>
                <template #icon="props">
                    <img :src="props.active ? '/static/my-selected.png' :  '/static/my.png' " />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        props: ['name','ifCanChange'],
        name: '',
        data() {
            return {
                active: 'home',
            }
        },
        mounted() {
            this.active = this.name
        },
        methods: {
            onChange(e) {
                console.log("tabbar_this_ifcanchange",this.ifCanChange)
                if(!this.ifCanChange){ //如果ifCanChange为true则可以切换tabbar栏跳转页面否则停留在me页面
                    this.active = 'me'
                    return
                }
                if (e == 'home') {
                    this.$router.replace({
                        path: '/project'
                    })
                } else if(e == 'video'){
                    this.$router.replace({
                        path: '/video_center'
                    })
                } else if(e == 'class'){
                    this.$router.replace({
                        path: '/class_center'
                    })
                } else if(e == 'me') {
                    this.$router.replace({
                        path: '/me'
                    })
                }
            },
            onTabbar(){
                this.$emit('clickTabbar',true) //点击tabbar栏时触发事件
            }
        }
    }
</script>

<style lang="scss" scoped>
.text_default{
    color: #929BAD;
    font-weight:500;
}
.text_active{
    color: #4B99FF;
    font-weight:500;
}
img{
    width: 30px;
    height: 30px;
}
</style>