<template>
    <!-- 视频中心 -->
    <div class="video_center">
        <!-- 按钮导航 -->
        <div class="btn_box_outer">
            <div class="btn_box">
                <div class="btn_bg" @click="toRecording()">
                    <van-icon name="video" size="30" color="#fff"/>
                </div>
                <span class="btn_text" >录制</span>
            </div>
            <div class="btn_box" @click="Storage()">
                <div class="btn_bg">
                    <van-icon name="video" size="30" color="#fff"/>
                </div>
                <span class="btn_text">视频库</span>
            </div>
            <div class="btn_box">
                <div class="btn_bg" @click="toDownloadCenter()">
                    <van-icon name="video" size="30" color="#fff"/>
                </div>
                <span class="btn_text">下载中心</span>
            </div>
        </div>
        <!-- 病例分享 -->
        <div class="video_box_outer">
            <!-- 一条卡片信息 start-->
            <div class="video_box">
                <div class="img_box">
                    <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                </div>
                <div class="detail_box">
                    <div class="detail">
                        <span class="detail_title">【病例分享】肺癌科研1号</span>
                        <span class="detail_date">2021.08.02</span>
                        <span class="detail_status"><van-icon name="checked" />审核成功</span>
                    </div>
                    <van-icon class="detail_icon" name="ellipsis" />
                </div>
            </div>
            <!-- end -->
        </div>
        <Tabbar :name="'video'"></Tabbar>
    </div>
</template>

<script>
import Vue from 'vue';
import { Icon } from 'vant';

Vue.use(Icon);
import Tabbar from '@/components/tabbar.vue'
export default {
    name: '',
    components: {
        Tabbar
    },
    methods: {
        // 点击录制
        toRecording(){
            this.$router.push({
                path: '/class_library',
            })
        },
        // 点击视频库
        Storage() {
            this.$router.push({
                path: '/video_storage'
            })
        },
        // 点击下载中心
        toDownloadCenter(){
            this.$router.push({
                path: '/download_center',
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.video_center{
    .video_box_outer{
        .video_box{
            // background: #ccc;
            margin: 15px;
            display: flex;
            // align-items: center;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
            .img_box{
                display: flex;
                padding: 9px;
            }
            .detail_box{
                display: flex;
                // background: #ccc;
                width: 100%;
                justify-content: space-between;
                .detail{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;
                    .detail_title{
                        font-size: 14px;
                        color: #101010;
                    }
                    .detail_date{
                        font-size: 12px;
                        color: #101010;
                    }
                    .detail_status{
                        font-size: 12px;
                        color: #15DB4E;
                        display: flex;
                        align-items: center;
                    }
                }
                .detail_icon{
                    margin-right: 5px;
                }
            }
        }
    }
    .btn_box_outer{
        display: flex;
        justify-content: space-around;
        
        .btn_box{
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn_bg{
                width: 40px;
                height: 40px;
                background: #2788FF;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .btn_text{
                font-size: 14px;
            }
        }   
    }
}
</style>